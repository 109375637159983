import './app.css';
import Nav from "./Nav";

function Header() {
    return (
        <div className="header bg-header-bg bg-no-repeat bg-bottom text-white uppercase text-center">
            <h1 className="text-4xl p-20 flex justify-center sm:text-6xl">BIGFOLLOWS</h1>
            <Nav></Nav>
            <h2 className="text-2xl md:text-5xl py-28">Quality promotion<br />
              and increasing the profitability<br />
              of your Twitch channel</h2>
            <img src="/arrow.png" className="inline object-center pb-36 transition animate-bounce" alt="Arrow" />
        </div>
    );
}

export default Header;