import './app.css';
function Footer() {
  return (
    <div className="footer flex flex-wrap justify-around bg-footer text-white p-14 text-xl gap-10">
      <h1>TERMS AND CONDITIONS</h1>
      <h1>PRIVACY POLICY</h1>
      <h1>SEND FEEDBACK</h1>
    </div>
  );
}

export default Footer;