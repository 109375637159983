import './app.css';

function ProductWhite(props) {
  return (
    <div className="product-fade drop-shadow-md">
      <h1 className="font-4xl text-white">{props.name}</h1>
      <div className="flex flex-col uppercase bg-gray-200 gap-4 rounded-3xl w-52">
        <h3 className="pt-10">{props.title}</h3>
        <h3>HIGH QUALITY</h3>
        <h3>REAL NAMES</h3>
        <h3 className="pb-20 px-1">MOST OF ACCOUNTS HAVE AVATARS</h3>
      </div>
      <a href={props.link}>
      <div className="whiteproduct_button button transition text-xl hover:-translate-y-2 ml-8 flex-col bg-gray-200 -top-10 relative justify-center z-10 w-36 py-3.5 object-center rounded-3xl">
        <h1>{props.price}</h1>
        <h1>PURCHASE</h1>
      </div>
      </a>
    </div>
  );
}

export default ProductWhite;