import './app.css';

function ProductFade(props) {
  return (
    <div className="product-fade">
      <h1 className="font-4xl">{props.name}</h1>
    <div className="flex flex-col uppercase border-4 border-purple-600 gap-4 rounded-3xl w-52">
          <h3 className="pt-10">{props.title}</h3>
          <h3>HIGH QUALITY</h3>
          <h3>REAL NAMES</h3>
          <h3 className="pb-20">MOST OF ACCOUNTS HAVE AVATARS</h3>
    </div>
      <a href={props.link}>
      <div className="button transition text-xl hover:-translate-y-2 ml-8 flex-col -top-8 relative justify-center z-10 w-36 py-3.5 drop-shadow-lg justify-center object-center text-white rounded-3xl bg-gradient-to-t from-purple-700 to-fuchsia-700">
        <h1>{props.price}</h1>
        <h1>PURCHASE</h1>
      </div>
      </a>
    </div>
  );
}

export default ProductFade;