import './index.css';
import Header from "./Header";
import Followers from "./Followers";
import ProductFade from "./ProductFade";
import Subscribers from "./Subscribers";
import ProductWhite from "./ProductWhite";
import Viewers from "./Viewers";
import DailyViewers from "./DailyViewers";
import Footer from "./Footer";

function App() {
    return (
        <div className="App">
            <div className="header">
                <Header />
            </div>
            <div className="followers bg-main bg-no-repeat bg-cover">
                <Followers />
                <div className="flex flex-wrap justify-center gap-10 py-20 px-10">
                    <ProductFade name="TEST" title="200 FOLLOWERS" price="$2.1" link="https://goodness.selly.store/product/f763a60b"/>
                    <ProductFade name="NEWBIE" title="1000 FOLLOWERS" price="$5" link="https://goodness.selly.store/product/5ad8ed98" />
                    <ProductFade name="BOOST" title="5000 FOLLOWERS" price="$20" link="https://goodness.selly.store/product/6e69e4e3" />
                    <ProductFade name="PRO" title="10000 FOLLOWERS" price="$35" link="https://goodness.selly.store/product/c69ef7dc" />
                </div>
            </div>
            <div className="subs bg-main2 bg-no-repeat bg-cover">
                <div className="relative">
                    <img src="/figure1.png" className="absolute -top-52 -left-40 object-left" alt="figure" />
                </div>
                <Subscribers />
                <div className="flex flex-wrap justify-center gap-10 py-20 px-10">
                    <ProductWhite name="NEWBIE" title="1000 BITS" price="$5" link="https://goodness.selly.store/product/9b57ab24" />
                    <ProductWhite name="BOOST" title="10000 BITS" price="$40" link="https://goodness.selly.store/product/f255c37c" />
                    <ProductWhite name="BOOST" title="10 PRIME" price="$14" link="https://goodness.selly.store/product/403d1e82" />
                    <ProductWhite name="BOOST" title="50 PRIME" price="$70" link="https://goodness.selly.store/product/0e9f036f" />
                </div>
            </div>
            <div className="viewers bg-main3 bg-no-repeat bg-cover overflow-hidden">
                <div className="relative">
                    <img src="/figure2.png" className="absolute top-10 -right-14" alt="figure2" />
                </div>
                <Viewers />
                <div className="flex flex-wrap justify-center gap-10 py-20 px-10">
                    <ProductFade name="TEST" title="10 VIEWERS" price="$2.2"
                                 link="https://goodness.selly.store/product/a3754c4d"/>
                    <ProductFade name="NEWBIE" title="20 VIEWERS" price="$2.2"
                                 link="https://goodness.selly.store/product/345a6226"/>
                    <ProductFade name="BOOST" title="50 VIEWERS" price="$2.2"
                                 link="https://goodness.selly.store/product/86d4b664"/>
                    <ProductFade name="PRO" title="75 VIEWERS" price="$3"
                                 link="https://goodness.selly.store/product/12d18d1b"/>
                    <ProductFade name="PRO" title="100 VIEWERS" price="$3.99"
                                 link="https://goodness.selly.store/product/475109ad"/>
                    <ProductFade name="PRO" title="150 VIEWERS" price="$5.99"
                                 link="https://goodness.selly.store/product/a72d0ba3"/>
                    <ProductFade name="PRO" title="200 VIEWERS" price="$7.99"
                                 link="https://goodness.selly.store/product/94171bcf"/>
                    <ProductFade name="PRO" title="300 VIEWERS" price="$9.99"
                                 link="https://goodness.selly.store/product/a39bb887"/>
                </div>
            </div>
            <div className="subs bg-main4 bg-no-repeat bg-cover">
                <div className="relative">
                    <img src="/figure3.png" className="absolute -top-52 -left-32" alt="figure3" />
                </div>
                <DailyViewers />
                <div className="flex flex-wrap justify-center gap-10 py-20 px-10">
                    <ProductWhite name="NEWBIE" title="10 VIEWERS" price="$12" link="https://goodness.selly.store/product/88efacd8" />
                    <ProductWhite name="BOOST" title="20 VIEWERS" price="$12" link="https://goodness.selly.store/product/1a6d100d" />
                    <ProductWhite name="PRO" title="50 VIEWERS" price="$12" link="https://goodness.selly.store/product/1d591579" />
                    <ProductWhite name="PRO" title="75 VIEWERS" price="$16" link="https://goodness.selly.store/product/7d6a0bcd" />
                    <ProductWhite name="PRO" title="100 VIEWERS" price="$20" link="https://goodness.selly.store/product/21f296cc" />
                    <ProductWhite name="PRO" title="150 VIEWERS" price="$25" link="https://goodness.selly.store/product/a4404e87" />
                    <ProductWhite name="PRO" title="200 VIEWERS" price="$30" link="https://goodness.selly.store/product/f73f8f59" />
                    <ProductWhite name="PRO" title="300 VIEWERS" price="$40" link="https://goodness.selly.store/product/80e089d6" />
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
