import './app.css';
function Nav() {
    return (
        <div className="nav">
            <ul className="flex justify-around uppercase text-white font-bold flex-wrap gap-5 text-lg sm:text-xl">
                <a href="#followers"><li className="border-4 border-white border-dashed rounded-full py-2 px-14 hover:bg-white hover:text-black transition duration-300">Followers</li></a>
                <a href="#subs"><li className="border-4 border-white border-dashed rounded-full py-2 px-14 hover:bg-white hover:text-black transition duration-300">Subscribers</li></a>
                <a href="#viewers"><li className="border-4 border-white border-dashed rounded-full py-2 px-14 hover:bg-white hover:text-black transition duration-300">Viewers</li></a>
            </ul>
        </div>
    );
}

export default Nav;